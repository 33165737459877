import React from 'react';
import {
  makeStyles,
  Paper,
  Tabs, Tab, Box
} from "@material-ui/core";
import PrivateRoute from "../PrivateRoute";
import {Switch} from "react-router-dom";
import Assessment from "./Assessment";
import {AssessmentsContextProvider} from "./assessments.context";
import {FacilityAssessments} from "./facility";
import {GlobalAssessments} from "./global";
import {PageTitle} from "../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(.5, 0),
    padding: theme.spacing(1)
  },
  createBtn: {
    marginLeft: theme.spacing(1)
  }
}))

const Assessments = () => {
  const classes = useStyles();
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Paper elevation={24} className={classes.root}>
      <PageTitle title={"Assessments"}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Facility Assessments" />
          <Tab label="Predefined Global Assessments" />
        </Tabs>
      </PageTitle>
      <Box mt={1}>
        {tabValue === 0 &&
          <FacilityAssessments />
        }
        {tabValue === 1 &&
          <GlobalAssessments />
        }
      </Box>
    </Paper>
  )
}


const AssessmentsWrapper = () => {
  return (
    <AssessmentsContextProvider>
      <Switch>
        <PrivateRoute exact path={"/assessments"} component={Assessments} />
        <PrivateRoute path={"/assessments/:id"} component={Assessment} />
      </Switch>
    </AssessmentsContextProvider>
  )
}

export default AssessmentsWrapper;
