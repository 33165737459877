import React from 'react';
import GlobalAssessmentsTable from "./GlobalAssessmentsTable";
import ConfirmDialog from "../../utils/dialogs/confirm.dialog";

export const GlobalAssessments = () => {
  const [viewDialog, setViewDialog] = React.useState(false);
  const [selectedAssessment, setSelectedAssessment] = React.useState(null);

  const handleCopyAssessmentDialog = (assessment) => {
    setSelectedAssessment(assessment);
    setViewDialog(true);
  }

  const handleCopyAssessment = () => {
    setViewDialog(false);
  }

  return (
    <div>
      <GlobalAssessmentsTable onCopyAssessment={handleCopyAssessmentDialog} />
      <ConfirmDialog title={`Copy Assessment | ${selectedAssessment?.name}`}
                     message={"Are you sure you want to copy this assessment?"}
                     onConfirm={handleCopyAssessment}
                     open={viewDialog}
                     onClose={() => setViewDialog(false)}
                     displayConfirmButton={true}
                     confirmText={"Copy"}
      />
    </div>
  )
}
