import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import {DeleteBtn} from "../buttons/DeleteBtn";

const ConfirmDialog = ({ open,
                         onClose,
                         onConfirm,
                         maxWidth = "sm",
                         fullWidth = true,
                         title = "Please Confirm",
                         message = "Press confirm to proceed with operation",
                         description = null,
                         confirmText = "Delete",
                         displayConfirmButton = false
                       }) => {
  return (
    <Dialog open={open}
            onClose={onClose}
            maxWidth={maxWidth}
            fullWidth={fullWidth}
    >
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{message}</DialogContentText>
        {description && <DialogContentText>{description}</DialogContentText>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
          {!displayConfirmButton && <DeleteBtn buttonText={confirmText} onClick={onConfirm} />}
          {displayConfirmButton && <Button variant={"text"} color={"primary"} onClick={onConfirm}>{confirmText}</Button>}
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog;
