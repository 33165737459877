import React from 'react';

import { UserContext} from "../context/usercontext";

const useHasRole = requiredPermission => {
  const { user } = React.useContext(UserContext);

  const checkPermission = permission => {
    if (!permission) return false;
    if (user?.roles) {
      console.log('roles', user?.roles);
      // alert(JSON.stringify(state.selectedTenantLocation, null, 2))
      const userRoles = user?.roles?.filter(userRole =>
        userRole?.role?.permissions?.findIndex(perm => perm === permission) > -1)
      return userRoles.length > 0

    } else {
      return false;
    }
  }

  return { checkPermission }
}

export default useHasRole
