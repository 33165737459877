// Import necessary modules
import React, {useState, useEffect, useContext} from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableSortLabel,
  Paper, TextField, Select, MenuItem, Button, Chip, Box, Checkbox, Typography,
} from "@material-ui/core";
import {
  addTenantToAssessment, copyAssessment, createAssessment,
  getAdminAssessments,
  removeTenantToAssessment
} from "../../../services/assessment.service";
import {getStripedStyle} from "../../utils/table.utils";
import {Link} from "react-router-dom";
import {convertToFilters} from "../../../utils/filter.utils";
import {FileCopy, HighlightOffTwoTone} from "@material-ui/icons";
import TableLoadingIndicator from "../../../utils/indicators/table.loading";
import {UserContext} from "../../../context/usercontext";
import useGlobalNotification from "../../../hooks/notification.hook";
import useHasRole from "../../../hooks/user.role.hook";
import ConfirmDialog from "../../utils/dialogs/confirm.dialog";
import AddIcon from "@material-ui/icons/Add";
import CreateAssessment from "../create.assessment";

const initialFilter = {
    id: {
      field: 'id',
      minValue: 3,
      value: null,
      operator: '*=*',
    },
    tags: {
      field: 'tags',
      minValue: 3,
      value: null,
      operator: '*=*',
    },
    name: {
      field: 'name',
      minValue: 3,
      value: null,
      operator: '*=*',
    },
    type: {
      field: 'type',
      minValue: 3,
      value: null,
      operator: '*=*',
    },
    description: {
      field: 'description',
      minValue: 3,
      value: null,
      operator: '*=*',
    },
    isGlobal: {
      field: 'isGlobal',
      minValue: 0,
      value: true,
      operator: 'boolean'
    },
    tenant: {
      field: 'tenant',
      minValue: 1,
      value: null,
      operator: '=in=',
    },
    status: {
      field: 'status',
      minValue: 3,
      value: "Active",
      operator: '*=*',
    },
  };


const GlobalAssessmentsTable = ({
                                  initialPage = 1,
                                  initialSize = 25,
                                  defaultSortField = "name",
                                  defaultSortDirection = "asc"
                                }) => {
  // State for fetched data
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  // State for pagination and sorting
  const [page, setPage] = useState(initialPage);
  const [size, setSize] = useState(initialSize);
  const [filter, setFilter] = useState(JSON.parse(JSON.stringify(initialFilter)));
  const [sortField, setSortField] = useState(defaultSortField);
  const [sortDirection, setSortDirection] = useState(defaultSortDirection);

  // State for create assessment
  const [viewCreateAssessmentModal, setViewCreateAssessmentModal] = useState(false);

  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [viewConfirmCopyDialog, setViewConfirmCopyDialog] = useState(null);
  const { addNotification } = useGlobalNotification();
  const { user, selectedTenant } = useContext(UserContext);
  const {checkPermission} = useHasRole();
  const canCreate = checkPermission('ASSESSMENT_CREATE');

  // Fetch data when component mounts or dependencies change
  useEffect(() => {
    const fetchData = async () => {
      await getData(page, size, filter, sortField, sortDirection);
    };
    fetchData().then(() => {});
  }, [page, size, filter, sortField, sortDirection]);

  const getData = async (pg = 1, sz = 25, filt = null, srt = 'name', srtDirection = 'asc') => {
    setLoading(true);
    const filters= convertToFilters(filt);
    const fetchedData = await getAdminAssessments(pg, sz, filters, srt, srtDirection);
    setRows(fetchedData?.content || []);
    setLoading(false);
  }

  // Handle sorting logic
  const handleSort = (field) => {
    const isAsc = sortField === field && sortDirection === "asc";
    setSortDirection(isAsc ? "desc" : "asc");
    setSortField(field);
  };

  // Handle pagination
  const handlePageChange = (event, newPage) => setPage(newPage + 1);
  const handleSizeChange = (event) => setSize(parseInt(event.target.value, 10));

  const filterTextChange = async e => {
    const obj = {...filter};
    obj[e.target.name].value = e.target.value;
    setFilter(obj);
    if (e.target.value.length >= obj[e.target.name].minValue) {
      await getData(page, size, obj, sortField, sortDirection);
    } else if (e.target.value.length === 0) {
      await getData(page, size, filter, sortField, sortDirection);
    }
  }

  const onChangeStatusFilter = async e => {
    const obj = {...filter};
    obj.status.value = e.target.value === -1 ? null: e.target.value;
    setFilter(obj);
    await getData(page, size, obj, sortField, sortDirection);
  }

  const getCheckedValue = assessment => {
    return assessment?.enabledForTenants?.some(t => t === selectedTenant?._id) || false;
  }

  const handleCheckedChange = async (event, assessment) => {
    const tenantId = selectedTenant?._id;
    const assessmentId = assessment?._id;

    const updateRows = (modifierFn) => {
      setRows(rows.map((row) => {
        if (row?._id === assessmentId) {
          row.enabledForTenants = modifierFn(row.enabledForTenants);
        }
        return row;
      }));
    };

    if (!tenantId || !assessmentId) return; // Guard clause for missing IDs

    if (!event?.target?.checked) {
      const apiResponse = await removeTenantToAssessment(assessmentId, tenantId);

      if (apiResponse?.error) {
        addNotification(String(apiResponse?.message), "error");
        return;
      }
      updateRows((enabledTenants) => enabledTenants?.filter((id) => id !== tenantId));
      addNotification("Tenant removed from assessment", "success");
    } else {
      const apiResponse = await addTenantToAssessment(assessmentId, tenantId);

      if (apiResponse?.error) {
        addNotification(String(apiResponse?.message), "error");
        return;
      }

      updateRows((enabledTenants) => [...enabledTenants, tenantId]);
      addNotification("Tenant added to assessment", "success");
    }
  };

  const onCopyAssessment = async assessment => {
    const response = await copyAssessment(assessment?._id);
    if (!response?.error) {
      addNotification("Assessment copied successfully", "success");
    } else {
      addNotification(String(response?.message), "error");
    }
    setViewConfirmCopyDialog(false);
  }

  const handleCopyAssessmentClick = (assessment) => {
    setSelectedAssessment(assessment);
    setViewConfirmCopyDialog(true);
  }

  const handleCreateAssessmentClick = () => {
    setViewCreateAssessmentModal(true);
  }

  const onCreateAssessment = async assessment => {
    const result = await createAssessment(assessment);
    if (!result?.error) {
      addNotification("Assessment created successfully", "success");
      setViewCreateAssessmentModal(false);
      await getData(page, size, filter, sortField, sortDirection);
    } else {
      addNotification(String(result?.message), "error");
      setViewCreateAssessmentModal(false);
    }
  }

  const clearFilters = async () => {
    setPage(1);
    setSize(25);
    setFilter(JSON.parse(JSON.stringify(initialFilter)));
    setSortField(defaultSortField);
    setSortDirection(defaultSortDirection);
  }

  // Render the table
  return (
    <Paper>
      <ConfirmDialog open={viewConfirmCopyDialog}
                     title={`Copy Assessment | ${selectedAssessment?.name || 'Unknown'}`}
                     message={"Are you sure you want to copy this assessment?"}
                     description={"This will create a new assessment with the same details, that you can customize."}
                     onConfirm={() => onCopyAssessment(selectedAssessment)}
                     displayConfirmButton={true}
                     confirmText={`Copy Assessment`}
                     onClose={() => setViewConfirmCopyDialog(false)}
      />

      <div>
        {user?.isSystemAdmin &&
          <>
            <Button size={"small"}
                    onClick={handleCreateAssessmentClick}
                    startIcon={<AddIcon/>}>Create</Button>
            <CreateAssessment onSave={onCreateAssessment}
                              open={viewCreateAssessmentModal}
                              isGlobal={true}
                              onClose={() => setViewCreateAssessmentModal(false)}/>
          </>
        }
        <Button startIcon={<HighlightOffTwoTone/>}
                onClick={clearFilters}
                size={"small"}>Clear Filters</Button>

      </div>
      <TablePagination
        component="div"
        count={rows.length} // Set total count of items
        page={page - 1}
        onPageChange={handlePageChange}
        rowsPerPage={size}
        onRowsPerPageChange={handleSizeChange}
        rowsPerPageOptions={[10, 25, 50]} // Options for page size
      />
      <TableContainer>
        <Table size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell>
                Use Assessment
              </TableCell>
              {canCreate &&
                <TableCell/>
              }
              <TableCell>
                <TableSortLabel
                  active={sortField === 'name'}
                  direction={sortDirection}
                  onClick={() => handleSort('name')}
                >
                  Name
                </TableSortLabel>
                <TextField
                  fullWidth
                  name="name"
                  margin="dense"
                  size="small"
                  placeholder="Search"
                  variant="outlined"
                  value={filter?.name?.value || ''}
                  onChange={filterTextChange}
                ></TextField>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === 'description'}
                  direction={sortDirection}
                  onClick={() => handleSort('description')}
                >
                  Description
                </TableSortLabel>
                <TextField
                  fullWidth
                  name="description"
                  margin="dense"
                  size="small"
                  placeholder="Search"
                  variant="outlined"
                  value={filter?.description?.value || ''}
                  onChange={filterTextChange}
                ></TextField>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === 'status'}
                  direction={sortDirection}
                  onClick={() => handleSort('status')}
                >
                  Status
                </TableSortLabel>
                <Select fullWidth
                        value={filter?.status?.value || ''}
                        onChange={onChangeStatusFilter}
                        displayEmpty
                >
                  <MenuItem value={"-1"}>All</MenuItem>
                  <MenuItem value={"Active"}>Active</MenuItem>
                  <MenuItem value={"Development in progress"}>Development in progress</MenuItem>
                  <MenuItem value={"Inactive"}>Inactive</MenuItem>
                </Select>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === 'tags'}
                  direction={sortDirection}
                  onClick={() => handleSort('tags')}
                >
                  Tags
                </TableSortLabel>
                <TextField
                  fullWidth
                  name="tags"
                  margin="dense"
                  size="small"
                  placeholder="Search"
                  variant="outlined"
                  value={filter?.tags?.value || ''}
                  onChange={filterTextChange}
                ></TextField>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === 'type'}
                  direction={sortDirection}
                  onClick={() => handleSort('type')}
                >
                  Type
                </TableSortLabel>
                <TextField
                  fullWidth
                  name="type"
                  margin="dense"
                  size="small"
                  placeholder="Search"
                  variant="outlined"
                  value={filter?.type?.value || ''}
                  onChange={filterTextChange}
                ></TextField>
              </TableCell>
            </TableRow>
          </TableHead>
          {loading &&
            <TableLoadingIndicator bodyOnly={true} cols={canCreate ? 6 : 5} rows={10}/>
          }
          <TableBody>
            {!loading && rows?.length > 0 ? (
              rows?.map((assessment, i) =>
                <TableRow key={i} style={getStripedStyle(i)}>
                  <TableCell>
                    {canCreate &&
                      <Checkbox
                        disabled={assessment?.status !== "Active"}
                        checked={getCheckedValue(assessment)}
                        onClick={(event) => handleCheckedChange(event, assessment)}
                      />
                    }
                    {!canCreate &&
                      <Typography variant={"body2"}>
                        {assessment?.enabledForTenants?.some(t => t === selectedTenant?._id) ? "Yes" : "No"}
                      </Typography>
                    }
                  </TableCell>
                  {canCreate &&
                    <TableCell>
                      <Button variant={"outlined"}
                              color={"primary"}
                              size={"small"}
                              startIcon={<FileCopy/>}
                              disabled={assessment?.status !== "Active"}
                              onClick={() => handleCopyAssessmentClick(assessment)}
                      >
                        Copy
                      </Button>
                    </TableCell>
                  }
                  <TableCell>
                    <Button component={Link} to={`/assessments/${assessment._id}`} color={"primary"}
                            size={"small"}>{assessment.name}</Button>
                  </TableCell>
                  <TableCell>{assessment.description}</TableCell>
                  <TableCell>{assessment?.status}</TableCell>
                  <TableCell>{assessment?.tags?.map(t => <Chip size={"small"} color={"primary"}
                                                               label={t}/>)}</TableCell>
                  <TableCell>{assessment.type}</TableCell>
                </TableRow>
              )
            ) : !loading ? (
              <TableRow>
                <TableCell colSpan={6}>
                  <Box textAlign="center" p={3}>
                    No data found.
                  </Box>
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default GlobalAssessmentsTable;
